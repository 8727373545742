<template>
  <div>
    <cc-datatable />

    <b-modal id="import-list" centered header-bg-variant="white" hide-footer>
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h3 class="mt-2 text-primary">Importar lista de clientes</h3>
        <div class="d-flex flex-column align-items-center w-75">
          <cc-dropzone ref="emissao" v-model="file" subtitle="Envie seu arquivo no formato XLSX." acceptFormats=".xlsx" />
        </div>
        <p><a
            href='/Template+importação+de+empresas.xlsx'>Clique
          aqui</a> para baixar o template.</p>
        <div class="mt-3 mb-3">
          <b-button class="mr-2" variant="outline-primary" @click="$bvModal.hide('import-list')">Cancelar</b-button>
          <b-button :disabled="!hasFile" variant="primary" @click="importClients">Importar</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {BButton, BCard, BCol, BModal, BRow, BTable} from 'bootstrap-vue'
import CcDatatable from '@/views/common/crud/components/Datatable.vue'
import CcDropzone from '@/@core/components/cc-dropzone/CcDropzone.vue';
import { EventBus } from '@/services/event_bus'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    CcDatatable,
    BModal,
    BButton,
    CcDropzone,
  },
  data() {
    return {
      file: {}
    }
  },
  mounted() {
    EventBus.$on('import-list', this.showImportList)
  },
  methods: {
    showImportList() {
      this.$bvModal.show('import-list')
    },
    responseDataErrosToString(erros) {
      let errosString = ''
      Object.keys(erros).forEach((key) => {
        errosString += `<b>Linha: ${key}:</b> ${erros[key]};<br>`
      })
      return errosString
    },
    getReturnData(data){
      let status = data.status
      if (status == 'falha') {
        return {
          title: 'Falha ao importar!',
          html: this.responseDataErrosToString(data.erros),
          icon: 'error',
          confirmButtonText: 'Ok'
        }
      }else{
        return {
          title: status == 'finalizado_com_sucesso' ? 'Sucesso!' : 'Importado com falhas!',
          html: status == 'finalizado_com_sucesso' ? 'Lista importada com sucesso!' : this.responseDataErrosToString(data.erros),
          icon: 'success',
          confirmButtonText: 'Ok'
        }
      }
    },
    async importClients() {
      try {
        this.$swal.showLoading()
        const { data } = await this.$store.dispatch('dashboard/importClients', this.file)
        this.$swal.fire(this.getReturnData(data))
        this.$bvModal.hide('import-list')
      } catch (error) {
        this.$swal.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro ao importar a lista!',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    },
  },
  computed: {
    hasFile() {
      return this.file instanceof File
    }
  }
}
</script>
